import type { CacheRequestConfig } from 'axios-cache-interceptor'
import type { Ref } from 'vue'
import type { LocationQueryRaw, RouteParamsRaw } from 'vue-router'
import type { IOffer } from './offer.types'

// #region consts {
export const HIDDEN_KEY = '✻✻✻✻✻✻✻✻'
export const USA_GEO_CENTER: IPosition = { lat: 39, lng: -95 }
export const CAD_GEO_CENTER: IPosition = { lat: 57, lng: -99 }
export const USA_CAD_GEO_CENTER: IPosition = { lat: 45, lng: -95 }
export const defaultRadius = 50 // km
export const OLIVE_BIRTH_DATE: Date = new Date('Feb 14, 2018')

// #region enums
export enum AUTOCOMPLETE_TYPE {
  ITEM = 'item',
  SUBHEADER = 'subheader',
}

export enum CONTRIBUTION_TYPE {
  MATCHING = 'matching',
  REWARD = 'reward',
  ROUND_UP = 'round_up',
  ROUND_UP_PERIOD_MIN_REMAINDER = 'round_up_period_min_remainder',
}

export enum ACTIVE_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum FILE_TYPE {
  TEXT = 'txt',
  CSV = 'csv',
  PDF = 'pdf',
}

export enum MODE {
  CREATE = 'Create',
  EDIT = 'Edit',
}

export enum SORT_ORDER {
  ASC = 'asc',
  DESC = 'desc',
}

export enum STATE_OR_PROVINCE {
  STATE = 'State',
  PROVINCE = 'Province',
}

export enum POSTALCODE_OR_ZIPCODE {
  POSTAL_CODE = 'Postal Code',
  ZIP_CODE = 'Zip Code',
}

export enum CURRENCY_CODE {
  CAD = 'CAD',
  USD = 'USD',
}

export enum CURRENCY_REGION {
  NA = 'North America',
  ALL = 'All',
}

export enum OLIVE_DETAILS_DISPLAY_TYPE {
  STRING = 'string',
  STRING_WITH_TOOLTIP = 'string_with_tooltip',
  NUMBER = 'number',
  CARD_SCHEME = 'cards',
  BOOLEAN = 'boolean',
  GUID = 'guid',
  COUNTRY = 'country',
  CURRENCY = 'currency',
  DATE = 'date',
  OFFER_STATE = 'offer_state',
  PERCENTAGE = 'percentage',
  REMAINDER = 'remainder',
  JSON = 'json',
}

export enum SUPPORTED_LOCALE {
  EN_US = 'en-US',
  EN_CA = 'en-CA',
  FR_CA = 'fr-CA',
}

export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export enum SELECTOR_TYPE {
  DATE = 'date',
  LOCALE = 'locale',
  NUMERIC = 'numeric',
  STRING = 'string',
  BOOLEAN = 'boolean',
  GUID = 'guid',
  CURRENCY = 'currency',
  MERCHANT_CATEGORY_CODE = 'merchant_category_code',
  AUTHORIZATION_STATUS = 'authorization_status',
  INTEGRATION_STATUS = 'integration_status',
  SETTLEMENT_STATUS = 'settlement_status',
  RETURN_STATUS = 'return_status',
  CARD_SCHEME = 'card_scheme',
  COUNTRY = 'country',
  CLIENT = 'client',
  CORPORATE = 'corporate',
  PAYMENT_METHOD = 'payment_method',
  PAYMENT_GATEWAY = 'payment_gateway',
  TRANSACTION_TYPE = 'transaction_type',
  TRIPLE_ACCOUNT_STATUS = 'triple_account_status',
}

export enum THEME {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum QUERY_OPERATOR_TYPE {
  BOOLEAN = 'boolean',
  STRING = 'string',
  NUMERIC = 'numeric',
}

export enum QUERY_COMPARISON_OPERATOR {
  EQ = 'eq', // ==
  NE = 'ne', // !=
  LT = 'lt', // <
  GT = 'gt', // >
  LE = 'le', // <=
  GE = 'ge', // >=
  CO = 'co', // contains
  NC = 'nc', // not contains
  SW = 'sw', // starts with
  EW = 'ew', // ends with
  NGT = 'ngt', // not >
  NLT = 'nlt', // not <
  NGE = 'nge', // not >=
  NLE = 'nle', // not <=
}

export enum PAGE_HEADER_BUTTON_TYPE {
  BUTTON = 'button',
  TOGGLE = 'toggle',
  BUTTON_AND_TOGGLE = 'button and toggle',
}

export enum TEXT_FIELD_TYPE {
  password = 'password',
  text = 'text',
  number = 'number',
}

// Keep enum lowercase as it's used to generate offer string
export enum DAYS_AVAILABILITY {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum CRON_EXPRESSION_DATE {
  NONE = 'none',
  DAILY = 'daily',
  WEEKLY_SUNDAY = 'weekly_on_sunday',
  WEEKLY_MONDAY = 'weekly_on_monday',
  SEMI_MONTHLY = 'semi_monthly',
  FIRST_DAY_OF_MONTH = 'first_day_of_the_month',
  LAST_DAY_OF_MONTH = 'last_day_of_the_month',
  CUSTOM = 'custom',
}

export enum MIME_TYPE {
  TEXT = 'text/plain',
  PDF = 'application/pdf',
  CSV = 'text/csv',
}

export enum SEARCH_TYPE {
  ID = 'id',
  TERM = 'term',
}
// #endregion

// #region interfaces
export interface IVueSelectItem {
  title: string
  value: string | boolean
}

export interface IVueSelectItemWithDescription extends IVueSelectItem {
  description: string
}

export interface IVueSelectItemWithBooleanOperators extends IVueSelectItem {
  isEqual?: boolean
}

export interface IGeoLocation {
  latitude?: number
  longitude?: number
  radius?: number // meters
}

export interface IPosition {
  lat: number
  lng: number
}

export interface IGoogleMarker {
  position: IPosition
  offer: IOffer
}

export interface IFormItem {
  id: string
  isValid: boolean | null
  errorMessages: string[]
}

export interface IOliveOfferSummary {
  title: string
  description: string
  tile: string
  qualifier: string
}

export interface IOliveDetailsDisplay {
  title: string
  value: string | number | boolean | string[] | number[]
  type: OLIVE_DETAILS_DISPLAY_TYPE
  routerLink?: string
  routerParams?: RouteParamsRaw
  routerQuery?: LocationQueryRaw
  expiryStatus?: -1 | 0 | 1
  textCapitalize?: boolean
  redacted?: boolean
  currencyCode?: string
  appendButton?: IOliveDetailsAppendedButton
  tooltipText?: string
}

export interface IOliveDetailsAppendedButton {
  title: string
  isLoading: boolean
  value: () => void
}

export interface IDateRangeModel {
  startDate?: string
  endDate?: string
}

export interface IOliveAPI<K> {
  method: HTTP_METHOD
  url: string | Ref<string>
  data?: any
  successMessage?: string
  errorMessage?: string
  defaultResponse?: K
  requestConfig?: CacheRequestConfig<any, any>
}

export interface IFilterQueryParamMapper {
  filterValue: any
  queryValue: any
}

export interface IIntervalHeader {
  currentPeriodDateLabel: string
  previousPeriodDateLabel: string
  dateHeader: string
}

export interface IToggle {
  title: string
  value: string
  icon?: string
  image?: string
}

export interface IVueDatePickerMonthPicker {
  month: number
  year: number
}
// #endregion

// #region types
type ParamType = string | string[] | number | number[] | boolean | boolean[] | undefined | null
export type OliveRequestParams = Record<string, ParamType>
// #endregion

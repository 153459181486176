import type { IOliveError } from '~/types/errors.types'
import type { IPlatformResponse } from '~/types/platform.types'
import type { IRole } from '~/types/roles.types'

import type { IAuthUser, IAuthUserRole, IUserInviteRequestParams, IUserRequestParams, JwtUserPayload } from '~/types/user.types'
import { jwtDecode } from 'jwt-decode'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { AuthenticationProperties as auth0 } from 'vue-auth0-plugin'
import { HTTP_METHOD } from '~/types/common.types'
import { PERMISSION } from '~/types/permission.types'

export const useUserStore = defineStore('users', () => {
  const oliveBaseUrl = '/users'

  // #region state
  const me = useStorage('me', {} as IAuthUser, sessionStorage)
  const users = ref<IAuthUser[]>([])
  const assignableRoles = ref<IRole[]>([])
  const userIdsCurrentPage = ref<string[]>([])
  const numberOfUsers = ref<number>(0)
  const isLoading = ref(false)
  const isLoadingAssignableRoles = ref(false)
  const error = ref<IOliveError>(getOliveError())

  const latestRequestTime = ref()
  // #endregion

  const checkUserPermission = (permission: PERMISSION): boolean | undefined => {
    return me.value.permissions?.includes(permission)
  }

  const hasNoRole = computed(() => {
    return !me.value.permissions || me.value.permissions.length === 0
  })

  // #region getters
  const canAccessAllClients = computed(() => {
    return checkUserPermission(PERMISSION.CAN_ACCESS_ALL_CLIENTS)
  })

  const getUsersCurrentPage = computed (() => {
    return users.value.filter(b => userIdsCurrentPage.value.includes(b.id))
  })

  const getUserById = computed(() => {
    return (id: string) => users.value.find(u => u.id === id)
  })

  const canUpdateUser = computed(() => {
    return (userId: string) => {
      const user = getUserById.value(userId)

      return checkUserPermission(PERMISSION.CAN_UPDATE_USERS)
        && (canAccessAllClients.value || user?.clientIds.every(c => me?.value.clientIds.includes(c)))
        && (canAccessAllClients.value || me.value.clientIds?.find(c => user?.corporates?.find(t => t.clientId === c)) !== undefined)
        && user?.permissions?.every(p => me.value.permissions?.includes(p))
    }
  })

  const isCorporateUser = computed(() => {
    return me.value.corporates?.length > 0 && me.value.clientIds?.length === 0
  })

  const getRolesByIds = computed(() => {
    return (ids: string[]) => assignableRoles.value.filter(c => ids.includes(c.id))
  })
  // #endregion

  // #region actions
  const loadMyUser = async () => {
    if (me.value.id)
      return

    const {
      response,
      error: userError,
      run: loadMyUser,
    } = useOliveAPI<IAuthUser>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/me`,
      errorMessage: 'Error loading user',
    })
    isLoading.value = true
    error.value = getOliveError()

    const userId = auth0.user?.sub
    if (userId && !me.value?.id) {
      const token = await auth0.getTokenSilently() as string
      const decoded = jwtDecode<JwtUserPayload>(token)

      await loadMyUser()

      if (response.value && !userError.value.hasError) {
        me.value = response.value
        me.value.permissions = decoded.permissions ?? []
      }
      else { error.value = userError.value }
    }

    isLoading.value = false
  }

  const loadUsers = async (params?: IUserRequestParams) => {
    const {
      response,
      error: userError,
      run: loadUsers,
    } = useOliveAPI<IPlatformResponse<IAuthUser>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(oliveBaseUrl, params),
      errorMessage: 'Error loading users',
    })
    isLoading.value = true
    error.value = getOliveError()

    const currentRequestDateTime = (new Date()).getTime()
    latestRequestTime.value = currentRequestDateTime

    await loadUsers()

    if (response.value?.items && !userError.value.hasError) {
      users.value = useArrayUnique([...response.value.items, ...users.value], (a, b) => a.id === b.id).value

      if (latestRequestTime.value === currentRequestDateTime) {
        userIdsCurrentPage.value = [...response.value.items.map(m => m.id)]
        numberOfUsers.value = response.value.totalNumberOfRecords
      }
    }
    else { error.value = userError.value }

    isLoading.value = false
  }

  const loadUser = async (id: string) => {
    if (getUserById.value(id))
      return

    const {
      response,
      error: userError,
      run: loadUser,
    } = useOliveAPI<IAuthUser>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/${id}`,
      errorMessage: 'Error loading user',
    })
    isLoading.value = true
    error.value = getOliveError()

    await loadUser()

    if (response.value && !userError.value.hasError)
      users.value = useArrayUnique([response.value, ...users.value], (a, b) => a.id === b.id).value
    else
      error.value = userError.value

    isLoading.value = false
  }

  const loadAssignableRoles = async () => {
    const {
      response,
      error: userError,
      run: loadAssignableRoles,
    } = useOliveAPI<IRole[]>({
      method: HTTP_METHOD.GET,
      url: '/roles/assignable',
      errorMessage: 'Failed to get assignable roles',
    })
    isLoadingAssignableRoles.value = true
    error.value = getOliveError()

    await loadAssignableRoles()

    if (response.value && !userError.value.hasError)
      assignableRoles.value = useArrayUnique([...response.value, ...assignableRoles.value], (a, b) => a.id === b.id).value
    else
      error.value = userError.value

    isLoadingAssignableRoles.value = false
  }

  const updateUser = async (updatedUser: IAuthUser) => {
    const {
      response,
      error: userError,
      run: updateUser,
    } = useOliveAPI<IAuthUser>({
      method: HTTP_METHOD.PATCH,
      url: `${oliveBaseUrl}/${updatedUser.id}`,
      data: updatedUser,
      successMessage: 'User updated successfully',
      errorMessage: 'Failed to update user',
    })
    isLoading.value = true
    error.value = getOliveError()

    await updateUser()

    if (response.value && !userError.value.hasError) {
      if (me.value.id === updatedUser.id)
        me.value = { ...response.value, permissions: me.value.permissions }
      else
        users.value = useArrayUnique([response.value, ...users.value], (a, b) => a.id === b.id).value
    }
    else { error.value = userError.value }

    isLoading.value = false
  }

  const inviteUser = async (user: IUserInviteRequestParams) => {
    const {
      error: userError,
      run: inviteUser,
    } = useOliveAPI({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}/invite`,
      data: user,
      successMessage: `${user.email} has been invited`,
      errorMessage: 'Failed to invite user',
    })
    isLoading.value = true
    error.value = getOliveError()

    await inviteUser()

    if (userError)
      error.value = userError.value

    isLoading.value = false
  }

  const unassignUser = async (userId: string, clientId?: string, corporateId?: string) => {
    const {
      error: userError,
      run: unassignUser,
    } = useOliveAPI({
      method: HTTP_METHOD.DELETE,
      url: clientId ? `${oliveBaseUrl}/${userId}/clients/${clientId}` : `${oliveBaseUrl}/${userId}/corporates/${corporateId}`,
      successMessage: clientId ? 'User was successfully unassigned from Client' : 'User was successfully unassigned from Corporate',
      errorMessage: 'Failed to unassign user',
    })
    isLoading.value = true
    error.value = getOliveError()

    await unassignUser()

    if (userError)
      error.value = userError.value

    isLoading.value = false
  }

  const assignUser = async (userId: string, clientId?: string, corporateId?: string) => {
    const {
      error: userError,
      run: assignUser,
    } = useOliveAPI({
      method: HTTP_METHOD.PATCH,
      url: clientId ? `${oliveBaseUrl}/${userId}/clients/${clientId}` : `${oliveBaseUrl}/${userId}/corporates/${corporateId}`,
      successMessage: clientId ? 'User was successfully assigned to Client' : 'User was successfully assigned to Corporate',
      errorMessage: 'Failed to unassign user',
    })
    isLoading.value = true
    error.value = getOliveError()

    await assignUser()

    if (userError)
      error.value = userError.value

    isLoading.value = false
  }

  const getUserRoles = async (userId: string) => {
    const {
      response,
      error: userError,
      run: getUserRoles,
    } = useOliveAPI<IAuthUserRole[]>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/${userId}/roles`,
      errorMessage: 'Failed to get roles for user',
    })
    isLoading.value = true
    error.value = getOliveError()

    await getUserRoles()

    if (response.value && !userError.value.hasError) {
      const user = getUserById.value(userId)
      if (user) {
        user.roles = response.value
        user.permissions = Array.from(new Set(response.value.flatMap(r => r.permissions)))
        users.value = useArrayUnique([user, ...users.value], (a, b) => a.id === b.id).value
      }
    }
    else { error.value = userError.value }

    isLoading.value = false
  }

  const addRole = async (userId: string, roleId: string) => {
    const {
      error: userError,
      run: addRole,
    } = useOliveAPI({
      method: HTTP_METHOD.PATCH,
      url: `${oliveBaseUrl}/${userId}/roles/${roleId}`,
      successMessage: 'User was successfully assigned role',
      errorMessage: 'Failed to assign role to user',
    })
    isLoadingAssignableRoles.value = true
    error.value = getOliveError()

    await addRole()

    if (userError)
      error.value = userError.value

    isLoadingAssignableRoles.value = false
  }

  const removeRole = async (userId: string, roleId: string) => {
    const {
      error: userError,
      run: removeRole,
    } = useOliveAPI({
      method: HTTP_METHOD.DELETE,
      url: `${oliveBaseUrl}/${userId}/roles/${roleId}`,
      successMessage: 'User was successfully unassigned role',
      errorMessage: 'Failed to unassign role from user',
    })
    isLoadingAssignableRoles.value = true
    error.value = getOliveError()

    await removeRole()

    if (userError)
      error.value = userError.value

    isLoadingAssignableRoles.value = false
  }

  const clearCurrentPage = () => {
    userIdsCurrentPage.value = []
  }

  const clearUsers = () => {
    users.value = []
  }
  // #endregion

  return {
    me,
    users,
    assignableRoles,
    canAccessAllClients,
    canUpdateUser,
    isCorporateUser,
    getUserById,
    getUsersCurrentPage,
    numberOfUsers,
    isLoading,
    error,
    loadMyUser,
    loadUsers,
    loadUser,
    loadAssignableRoles,
    isLoadingAssignableRoles,
    updateUser,
    inviteUser,
    assignUser,
    unassignUser,
    getUserRoles,
    getRolesByIds,
    addRole,
    removeRole,
    checkUserPermission,
    hasNoRole,
    clearCurrentPage,
    clearUsers,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserStore as any, import.meta.hot))
